[<RequireQualifiedAccess>]
module Index
//IN POWERSHELL RUN THIS COMMAND TO FIX THE ERROR
//
//$env:NODE_OPTIONS="--openssl-legacy-provider"
open Elmish
open Shared
open Feliz.Router
open Browser.Types
open Fable.Core

/// Status of the websocket.
type WsSender = WebSocketClientMessage -> unit
type BroadcastMode = ViaWebSocket | ViaHTTP
type ConnectionState =
    | DisconnectedFromServer | ConnectedToServer of WsSender | Connecting

    member this.IsConnected =
        match this with
        | ConnectedToServer _ -> true
        | DisconnectedFromServer | Connecting -> false

type Page =
    | CabinetSelector of CabinetSelector.State
    | PackingStation of PackingStation.State

type Model = {
        CurrentPage: Page
        CurrentUrl: string list
        Message: string
        ConnectionState : ConnectionState
        ReceivedMessages: string list
        }

type Msg =
    | PackingStationMsg of PackingStation.Msg
    | CabinetSelectorMsg of CabinetSelector.Msg
    | ChangeToPackingStationPage of int
    | ChangeToCabinetSelectorPage
    | UrlChanged of string list
    | ReceivedFromServer of WebSocketServerMessage
    | ConnectionChange of ConnectionState
    | MessageChanged of string
    | Broadcast of BroadcastMode * string
    | NoOp of unit

module Channel =
    open Browser.WebSocket
    open Browser.Dom

    let inline decode<'a> x =
        x
        |> unbox<string>
        |> Thoth.Json.Decode.Auto.unsafeFromString<'a>


    let buildWsSender (ws:WebSocket) : WsSender =
        fun (message:WebSocketClientMessage) ->
            let message = {| Topic = ""; Ref = ""; Payload = message |}
            let message = Thoth.Json.Encode.Auto.toString(0, message)
            ws.send message

    let subscription _ =

        let sub dispatch =
            /// Handles push messages from the server and relays them into Elmish messages.
            let onWebSocketMessage (msg:MessageEvent) =
                let msg2 = msg.data |> decode<{| payload : string |}>

                let decodedMsg =  decode<WebSocketServerMessage> msg2.payload

                decodedMsg
                |> ReceivedFromServer
                |> dispatch

            /// Continually tries to connect to the server websocket.
            let rec connect () =
                let protocol = if window.location.protocol = "https:" then "wss:" else "ws:"
                let host = window.location.host
                //swap out 8080 for 8085
                let hostFixed = host.Replace("8080", "8085")
                let wsUrl = sprintf "%s//%s%s" protocol hostFixed "/channel"
                let ws = WebSocket.Create wsUrl
                //let ws = WebSocket.Create "ws://192.168.0.143:8085/channel"
                ws.onmessage <- onWebSocketMessage
                ws.onopen <- (fun ev ->
                    dispatch (ConnectionChange (ConnectedToServer (buildWsSender ws)))
                    printfn "WebSocket opened")
                ws.onclose <- (fun ev ->
                    dispatch (ConnectionChange DisconnectedFromServer)
                    printfn "WebSocket closed. Retrying connection"
                    promise {
                        do! Promise.sleep 2000
                        dispatch (ConnectionChange Connecting)
                        connect() } |> ignore
                    ())
            connect()

        Cmd.ofSub sub

let init () : Model * Cmd<Msg> =
    let model = {
        CurrentPage = CabinetSelector (CabinetSelector.init())
        CurrentUrl  = Router.currentUrl ()
        Message     = ""
        ReceivedMessages = []
        ConnectionState = DisconnectedFromServer
        }
    model, Cmd.ofMsg (UrlChanged (Router.currentUrl()))

open Thoth.Elmish

let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
    match msg, model.CurrentPage with
    | ChangeToPackingStationPage x , _ ->
        { model with
              CurrentPage = PackingStation (PackingStation.init None x) },
        Cmd.none

    | ChangeToCabinetSelectorPage, _ ->
        JS.console.log "Changed to the cabinet selector page"
        { model with
              CurrentPage = CabinetSelector (CabinetSelector.init()) },
        Cmd.none
    | PackingStationMsg msg, Page.PackingStation packingStationState ->
        let (newModel, cmd) = PackingStation.update msg packingStationState
        { model with CurrentPage = PackingStation newModel }, Cmd.map PackingStationMsg cmd
    | CabinetSelectorMsg msg, Page.CabinetSelector cabinetSelectorState ->
        let (newModel, cmd) = CabinetSelector.update msg cabinetSelectorState
        { model with CurrentPage = CabinetSelector newModel }, Cmd.map CabinetSelectorMsg cmd
    | UrlChanged url, _ ->
        let cmd =   match url with
                    | [ "Packing"; Route.Int stationId] ->
                        Cmd.ofMsg (ChangeToPackingStationPage stationId)
                    | [ "Cabinets" ] ->
                        Cmd.ofMsg ChangeToCabinetSelectorPage
                    | _ ->
                        Cmd.ofMsg ChangeToCabinetSelectorPage

        {model with CurrentUrl = url }, cmd
    | MessageChanged msg, _ ->
        { model with Message = msg }, Cmd.none
    | ConnectionChange status, _ ->
        { model with ConnectionState = status }, Cmd.none
    | ReceivedFromServer ClearStations, _ ->
        match model.CurrentPage with
        | Page.PackingStation packingStationState ->
            let (newModel) = PackingStation.init None packingStationState.StationId
            {model with CurrentPage = PackingStation newModel }, Cmd.none
        | Page.CabinetSelector cabinetSelectorState ->
            { model with ReceivedMessages = "Clear stations":: model.ReceivedMessages }, Cmd.none
    | ReceivedFromServer (BroadCastCabinetChange cabinet), _ ->
        match model.CurrentPage with
        | Page.PackingStation packingStationState ->
            let (newModel, cmd) = PackingStation.update (PackingStation.Msg.ChangeCabinet cabinet) packingStationState
            printfn "Received message %s" cabinet.code
            printfn "New Model %A" newModel
            {model with CurrentPage = PackingStation newModel }, Cmd.map PackingStationMsg cmd
        | Page.CabinetSelector cabinetSelectorState ->
            { model with ReceivedMessages = cabinet.code :: model.ReceivedMessages }, Cmd.none
    | Broadcast (ViaWebSocket, msg), _ ->
        match model.ConnectionState with
        | ConnectedToServer sender -> sender (TextMessage msg)
        | _ -> ()
        model, Cmd.none
    | Broadcast (ViaHTTP, msg), _ ->
        model, Cmd.none
    | _, _ ->
        model, Cmd.none

open Feliz
open Feliz.Bulma

let stationLinks =
    Bulma.navbar [
        Bulma.color.isLight
        prop.children [
            Bulma.navbarBrand.div [
                Bulma.navbarItem.a [
                    prop.children [Html.img [prop.src "AmoriniLogoLarge.png" ; prop.height 35; prop.width 112;]]
                    prop.href "https://amorini.co.nz"
                ]
            ]
            Bulma.navbarStart.div [
                Bulma.navbarItem.a [
                    prop.text "Home"
                    prop.href (Router.format "Cabinets")
                ]
                for i in 1 .. 6 do
                    Bulma.navbarItem.a [
                        prop.text ("S" + i.ToString())
                        prop.href (Router.format ("Packing", i.ToString()))
                    ]
            ]
        ]
    ]

let content (model: Model) (dispatch: Msg -> unit) =
    Html.div [

        Html.div [
            match model.CurrentPage with
            | CabinetSelector state ->
                stationLinks
                CabinetSelector.render state (CabinetSelectorMsg >> dispatch)
            | PackingStation state ->
                PackingStation.render state (PackingStationMsg >> dispatch)
        ]
    ]

let view (model: Model) (dispatch: Msg -> unit) =
    React.router [
        router.onUrlChanged (UrlChanged >> dispatch)
        router.children [content model dispatch]
    ]
