module App

open Elmish
open Elmish.React
open Thoth.Elmish

#if DEBUG
open Elmish.Debug
open Elmish.HMR
#endif

Program.mkProgram Index.init Index.update Index.view
|> Program.withSubscription Index.Channel.subscription
|> Toast.Program.withToast Toast.render

#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
//
|> Program.run
