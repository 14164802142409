module CabinetSelector

open Elmish
open Fable.Remoting.Client
open Shared
open System
open Deferred
open Thoth.Elmish

type cabinetListItem = {
    id : Guid
    cabinet: Deferred<cabinet>
}

type State =
    {
      InputText: string
      MessagesSent: int
      currentCabinet: cabinet option
      cabinetList: cabinetListItem list
    }

type Msg =
    | SetInputText of string
    | AddCabinet
    | AddedCabinet of string
    | MessageSent
    | SendToPackingStations of cabinetListItem
    | GetCabinetInfo of AsyncOperationStatus<Result<cabinetListItem, ( cabinetListItem * string )>>
    | SetCurrentCabinet of cabinet
    | RemoveCabinetFromList of cabinetListItem
    | ClearPackingStations


let init() =
    {
        InputText = ""
        MessagesSent = 0
        currentCabinet = None
        cabinetList = []


    }
let createNewCabinetListItem (cabinet:Deferred<cabinet>) =
    {
        id = Guid.NewGuid()
        cabinet = cabinet
    }

let addItemToList (cabinetList: cabinetListItem list) (cabinet:cabinetListItem) =
    cabinetList @ [cabinet]

let removeItemFromCabList (cabinetList: cabinetListItem list) (cabinet: cabinetListItem) =
    let notThisCabinet (listItem: cabinetListItem) = (listItem.id <> cabinet.id)
    List.filter notThisCabinet cabinetList

let replaceCabinetItemFromList (cabinetList: cabinetListItem list) (cabinet: cabinetListItem) =
    let isThisCabinet (listItem: cabinetListItem) = (listItem.id = cabinet.id)
    let idx = List.findIndex isThisCabinet cabinetList

    let head =
        if (idx > 0) then
            cabinetList.GetSlice (Some 0 ,Some (idx-1 ))
        else
            []

    let tail =
        if ((idx + 1) >= cabinetList.Length) then
            []
        else
            cabinetList.GetSlice (Some (idx+1), None )
    head @ [cabinet] @ tail

let pacManApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IPacManApi>

let asynclookupCabinet cabinet =
    async {
        let! result = pacManApi.getCabinetInfo cabinet
        return result
    }

let update (cabinetSelectorMsg: Msg) (cabinetSelectorState : State) =
    match cabinetSelectorMsg with
    | ClearPackingStations ->
        cabinetSelectorState, Cmd.OfAsync.perform pacManApi.clearPackingStations () (fun () -> MessageSent)
    | SetInputText inputText ->
        {
            cabinetSelectorState with InputText = inputText
        },
        Cmd.none
    | AddCabinet ->
        {
            cabinetSelectorState with InputText = ""
        },
        Cmd.none
    | AddedCabinet cabinetName ->
        {
            cabinetSelectorState with
                InputText = ""
        },
        Cmd.none
    | SendToPackingStations (cabinetListItem: cabinetListItem) ->
        match cabinetListItem.cabinet with
        | HasNotStartedYet ->
            cabinetSelectorState, Cmd.none
        | InProgress ->
            cabinetSelectorState, Cmd.none
        | Resolved cabinet ->
            {cabinetSelectorState with cabinetList = (removeItemFromCabList cabinetSelectorState.cabinetList cabinetListItem )},Cmd.OfAsync.perform pacManApi.triggerBroadcast cabinet (fun () -> MessageSent)

    | MessageSent ->
        {cabinetSelectorState with MessagesSent = cabinetSelectorState.MessagesSent + 1 }, Cmd.none
    | GetCabinetInfo Started ->

        let cabinetListItem = createNewCabinetListItem InProgress

        let successFunction result =
            match result with
            | Ok cabinet ->
                let listItem = {
                    id = cabinetListItem.id
                    cabinet = (Resolved cabinet)
                }
                GetCabinetInfo (Finished (Ok listItem))
            | Error error ->
                GetCabinetInfo (Finished (Error (cabinetListItem, error)))

        let failureFunction (ex:exn) =
            GetCabinetInfo (Finished (Error (cabinetListItem, ("Server Error!\n" + ex.Message) )))

        let cmd =  Cmd.OfAsync.either asynclookupCabinet (cabinetSelectorState.InputText.Trim()) successFunction failureFunction

        {
            cabinetSelectorState with InputText = ""; cabinetList = (addItemToList  cabinetSelectorState.cabinetList cabinetListItem)

        }

        , cmd
    | GetCabinetInfo (Finished (Ok cabinet)) ->
        {
             cabinetSelectorState with  cabinetList = replaceCabinetItemFromList cabinetSelectorState.cabinetList cabinet
        }, Cmd.none
    | GetCabinetInfo (Finished (Error (cabinetListItem, errormsg))) ->
        {
             cabinetSelectorState with cabinetList = (removeItemFromCabList cabinetSelectorState.cabinetList cabinetListItem )

        }, Toast.message errormsg
            |> Toast.title "Error loading cabinet"
            |> Toast.position Toast.TopCenter
            |> Toast.timeout (TimeSpan.FromSeconds (15.0))
            |> Toast.dismissOnClick
            |> Toast.withCloseButton
            |> Toast.error

    | SetCurrentCabinet (cabinet: cabinet) ->
        {
            cabinetSelectorState with currentCabinet = Some cabinet
        }, Cmd.none
    | RemoveCabinetFromList (cabinetListItem : cabinetListItem) ->
        {
             cabinetSelectorState with cabinetList = (removeItemFromCabList cabinetSelectorState.cabinetList cabinetListItem )
        }, Cmd.none

open Feliz
open Feliz.Bulma

let renderLoadingCabinetFromQueue(cab: cabinetListItem) (dispatch: Msg -> unit) =
    Bulma.card [
        Bulma.color.hasBackgroundGreyLight
        Bulma.spacing.mb5
        prop.children   [
            Bulma.cardContent [
                Bulma.media [
                    Bulma.mediaLeft [
                        Bulma.cardImage [
                            Bulma.image [
                                Bulma.image.is32x32
                                prop.children [
                                    Html.img [
                                        prop.alt "Loading"
                                        prop.src "/images/spinning-loading.gif"
                                    ]
                                ]
                            ]
                        ]
                    ]
                    Bulma.mediaContent [
                        Bulma.title.p [
                            Bulma.title.is4
                            prop.text "Loading"
                        ]
                    ]
                ]
            ]
        ]
    ]

let renderLoadedCabinetFromQueue (cab: cabinetListItem) (cabinet: cabinet) (dispatch: Msg -> unit) =
    Bulma.card [
        Bulma.spacing.mb1
        prop.children   [
            Bulma.cardContent [
                Bulma.media [
                    Bulma.mediaLeft [
                        Bulma.cardImage [
                           (* Bulma.image [
                                Bulma.image.is64x64
                                prop.children [
                                    Html.img [
                                        prop.alt "Cabinet image"
                                        prop.src "/images/K-B600-2Door.png"
                                    ]
                                ]
                            ]*)
                        ]
                    ]
                    Bulma.mediaContent [
                        Bulma.title.p [
                            Bulma.title.is4
                            prop.text cabinet.code
                        ]
                        Bulma.title.p [
                            Bulma.title.is4
                            prop.text cabinet.sku
                        ]
                        Bulma.subtitle.p [
                            Bulma.title.is6
                            prop.text ("Number of parts " + (cabinet.list.Length.ToString()))
                        ]
                    ]
                ]
                Bulma.cardFooter [
                    Bulma.cardFooterItem.div [
                        Bulma.button.button [
                            prop.onClick ( fun _ -> dispatch (RemoveCabinetFromList cab ))
                            prop.text "Remove"
                        ]
                    ]
                    Bulma.cardFooterItem.div [
                        Bulma.button.button [
                            prop.onClick ( fun _ -> dispatch (SendToPackingStations cab) )
                            prop.text "Send"
                        ]
                    ]
                    Bulma.cardFooterItem.div [
                        Bulma.button.button [
                            prop.onClick ( fun _ -> dispatch (SetCurrentCabinet cabinet ))
                            prop.text "View Parts"
                        ]
                    ]
                ]
            ]
        ]
    ]


let renderCabinetFromQueue (cab:cabinetListItem) (dispatch: Msg -> unit) =

    match cab.cabinet with
    | HasNotStartedYet -> Html.p "Not yet started"
    | InProgress -> renderLoadingCabinetFromQueue cab dispatch
    | Resolved cabinet -> renderLoadedCabinetFromQueue cab cabinet dispatch

let renderCabinetPart (cabinetPart: string) (stationNumber:int) =
    Html.tr [
            prop.children [
                Html.th [
                    prop.text (stationNumber.ToString())
                    Bulma.spacing.px2
                ]
                Html.td [
                    prop.text cabinetPart
                    Bulma.spacing.px2
                ]
            ]
    ]

let render (state: State) (dispatch: Msg -> unit) =
    Html.div [
        Html.br []
        Bulma.columns [
            Bulma.column [
                column.isOneThird
            ]
            Bulma.column [
                column.isOneThird
                prop.children [
                    Bulma.box [
                        Bulma.field.div [
                            Bulma.label "Cabinet"
                            Bulma.control.div [
                                Bulma.input.text [
                                    prop.placeholder "Cabinet Code abc123"
                                    prop.value state.InputText
                                    prop.onChange (fun x -> SetInputText x |> dispatch)
                                    prop.onKeyPress (fun keyEvent ->
                                        if (keyEvent.key.Equals "Enter" && state.InputText.Length > 0)
                                        then dispatch (GetCabinetInfo Started)
                                    )
                                ]
                            ]
                        ]
                        Bulma.field.div [



                            prop.children [
                                Bulma.control.div [
                                    prop.style [
                                            style.padding (length.px 20)
                                            style.display.flex
                                            style.justifyContent.spaceBetween
                                        ]
                                    prop.children [
                                        Bulma.button.button [
                                            Bulma.color.isPrimary
                                            prop.onClick ( fun _ -> dispatch (GetCabinetInfo Started) )
                                            prop.disabled (state.InputText.Length < 1)
                                            prop.text "Add Cabinet"
                                        ]
                                        Bulma.button.button [
                                            Bulma.color.isDanger
                                            prop.onClick ( fun _ -> dispatch (ClearPackingStations) )

                                            prop.text "Clear Cabinet"
                                        ]
                                    ]
                                ]
                            ]
                        ]

                    ]
                ]
            ]
            Bulma.column [
                column.isOneThird
            ]
        ]
        Bulma.columns [
            Bulma.column [
                column.is1
            ]
            Bulma.column [
                column.is4
                prop.children [
                    for cabinet in state.cabinetList do
                        renderCabinetFromQueue cabinet dispatch
                ]
            ]
            Bulma.column [
                column.is2
            ]
            Bulma.column [
                column.is4
                Bulma.spacing.ml3
                prop.children [
                    match state.currentCabinet with
                    | None -> Html.div []
                    | Some cabinet ->
                        Bulma.title cabinet.sku
                        Html.span cabinet.code
                        Html.table [
                            Html.thead [
                                Html.tr [
                                    prop.children [
                                        Html.th [
                                            prop.text "QTY"
                                            Bulma.spacing.px2
                                        ]
                                        Html.th [
                                            prop.text "Part"
                                            spacing.px2
                                        ]
                                    ]
                                ]
                            ]
                            Html.tbody [
                                let parts = (cabinet.list |> List.map  (fun  ( part, i) ->  renderCabinetPart part i  ))
                                prop.children parts
                            ]

                        ]
                ]
            ]
            Bulma.column [
                column.is1
            ]
        ]
    ]

