namespace Shared

open System


module Route =
    let builder typeName methodName =
        sprintf "/api/%s/%s" typeName methodName


type cabinet = {
    description: string
    sku: string
    code: string
    list: (string * int) list
    cabinetType: string
}

type cabinetRepo = cabinet list


type IPacManApi =
    {
      triggerBroadcast: cabinet -> Async<unit>
      clearPackingStations: unit -> Async<unit>
      getCabinetInfo: string -> Async<Result<cabinet,string>>
      getStationImage: string * int * int -> Async<Result<byte[],string>>
    }

type WebSocketServerMessage =
    | BroadCastCabinetChange of cabinet
    | ClearStations
// Add more message that can go from client -> server here...
type WebSocketClientMessage =
    | TextMessage of string



