[<RequireQualifiedAccess>]
module PackingStation
open Fable.Remoting.Client
open Elmish
open Shared
open Deferred
open Thoth.Elmish
open System
let pacManApi =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Route.builder
    |> Remoting.buildProxy<IPacManApi>
type State =
    {
        Cabinet: cabinet option
        Img1: Deferred<Result<Byte[], string>>
        Img2: Deferred<Result<Byte[], string>>
        StationId: int
    }
type ImageNumber =
    | FirstImage
    | SecondImage

type Msg =
    | ChangeCabinet of cabinet
    | ErrorLoadingImage
    | GotImageFromServer of int * Result<Byte[], string>

let gotImageFromServer (imageId: int) (result: Result<Byte[], string>) =
    GotImageFromServer (imageId, result)
let ImageNumberToInt (imageNumber: ImageNumber) =
    match imageNumber with
    | FirstImage -> 1
    | SecondImage -> 2


let getImage (stationId:int) (imageNumber:ImageNumber) (cabinetCode: string) =
    Resolved (Ok ("/packing_images/" + cabinetCode + "/"+ (string stationId) + "/" + (string (ImageNumberToInt  imageNumber)) + ".png"))

let init (cabinetCode: string option) (stationNumber: int) =

    let code = Option.defaultValue "NO Cabinet Selected" cabinetCode

    {
        Cabinet = None
        Img1 = HasNotStartedYet
        Img2 = HasNotStartedYet
        StationId = stationNumber
    }

let update (packingStationMsg: Msg) (packingStationState : State) =
    match packingStationMsg with

    | ChangeCabinet cabinet->
        {
            packingStationState with
                Cabinet = Some cabinet
                Img1 = InProgress
                Img2 = InProgress

        },
        Cmd.batch [
            Cmd.OfAsync.either pacManApi.getStationImage (cabinet.sku, packingStationState.StationId, 1) (gotImageFromServer 1) (fun err -> gotImageFromServer 1 (Error err.Message))
            Cmd.OfAsync.either pacManApi.getStationImage (cabinet.sku, packingStationState.StationId, 2) (gotImageFromServer 2) (fun err -> gotImageFromServer 2 (Error err.Message))
        ]
    | GotImageFromServer (imageId, result) ->
        match imageId with
        | 1 ->
            { packingStationState with
                Img1 = Resolved result }, Cmd.none
        | 2 ->
            { packingStationState with
               Img2 = Resolved result }, Cmd.none
        | _ ->
            { packingStationState with
               Img2 = Resolved result }, Cmd.none

    | ErrorLoadingImage ->
        {
            packingStationState with
                Img1 = (Resolved (Error "images/error.png"))
                Img2 = (Resolved (Error "images/error.png"))


        },
        Toast.message "Failed to load image"
            |> Toast.title "Error loading cabinet"
            |> Toast.position Toast.TopCenter
            |> Toast.timeout (TimeSpan.FromSeconds (3.0))
            |> Toast.dismissOnClick
            |> Toast.withCloseButton
            |> Toast.error



open Feliz
open Feliz.Bulma

(*
    | HasNotStartedYet
    | InProgress
    | Resolved of 't
*)
let private bytesToBase64 (bytes: byte[]) =
    let base64 = Convert.ToBase64String(bytes)
    sprintf "data:image/jpeg;base64,%s" base64

let renderImg (deferredImageResult: Deferred<Result<Byte[], string>>)  (dispatch: Msg -> unit)=

    match deferredImageResult with
    | Resolved (Ok img) ->
        Bulma.image [
            prop.style [
                        style.padding (length.px 2)
                        style.borderStyle.solid
                        style.marginLeft (length.px 5)
                        style.marginRight (length.px 5)
                        style.margin.auto;

                    ]
            prop.children [
                Html.img [
                    prop.src (bytesToBase64 img)
                ]
            ]
            prop.key  (bytesToBase64 img)
        ]
    | InProgress ->
        Bulma.progress []
    | HasNotStartedYet ->
        Bulma.image [
            prop.style [style.margin.auto]
            prop.children [
                Html.img [
                    prop.src "amorini-logo.png"
                    prop.style [
                        style.width (length.perc 99)
                        style.padding (length.px 90)
                        //style.marginLeft (length.percent 50)
                        //style.transform.translateX (length.percent -50)
                        style.borderStyle.solid
                        style.marginLeft (length.px 10)
                        style.marginRight (length.px 10)

                    ]
                ]
            ]
        ]
    | Resolved (Error msg) ->
        Bulma.message [
            prop.text msg
            prop.style [
                style.textAlign.center
                style.fontSize 120
                style.color.gray
                style.minHeight (length.px 800)
            ]
            color.isDanger
        ]

let render (state: State) (dispatch: Msg -> unit) =
    React.fragment [
        Html.br []
        Html.br []
        Bulma.columns [
            columns.isCentered
            prop.children [
                Bulma.column [
                    column.is6
                    prop.children [
                        renderImg state.Img2 dispatch
                    ]
                ]
                Bulma.column [
                    column.is6
                    prop.children [
                        renderImg state.Img1 dispatch
                    ]
                ]
            ]
        ]
        Html.div [
            prop.style [
                style.width (length.percent 100)
                style.textAlign.center
                style.fontSize 60
                style.fontWeight 870
                style.fontFamily "Space Mono"


            ]

            let text =
                 state.Cabinet
                 |> Option.map (fun (cab:cabinet) -> $"[STATION: {state.StationId}] {cab.code} [{cab.sku}]" )
                 |> Option.defaultValue $"[STATION: {state.StationId}] NO PACK SELECTED"
            prop.text text//+ " Station " + $"{state.StationId}" )
        ]
    ]

